// include fullcalendar css
// currently in app.tsx, but should be in components
// using fullcalendar when nextjs updated to v10
// see https://nextjs.org/blog/next-10#importing-css-from-third-party-react-components
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/timegrid/main.css';
import '@formatjs/intl-locale/polyfill';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  NextPageContext,
} from 'next';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import 'normalize.css';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import svgSprite from '../../public/static/sprite.svg';

import * as LocaleContext from '../contexts/LocaleContext';
import * as ViewDeviceContext from '../contexts/ViewDeviceContext';
import store from '../redux';

import '../styles/global.css';

import I18n, { detectLocaleByAcceptLanguage } from '../utils/i18n';
import {
  setCoubicLocaleRequestHeader,
  setUaType,
} from '../utils/secured-axios';
import './_app.scss';
import nookies from 'nookies';
import Script from 'next/script';
import { userMeSwrKey, useUserMeFetcher, useUserMe } from '../hooks/api/me';
import { preload } from 'swr';
import { checkIsCoubicApp } from '../utils/mobileApp';
import { initializeDayjs } from 'src/libs/dayjs/initialize-dayjs';
import { SupervisorProxyHeader } from 'src/molecules/SupervisorProxyHeader';

initializeDayjs();

export interface PageContext extends NextPageContext {
  session: string;
  token: string;
  requestId: string;
}

const useLocale = () => {
  const router = useRouter();
  const { hl } = nookies.get();
  const { meResponseData } = useUserMe();
  const locale = (router.query.hl as string) || meResponseData?.locale || hl;
  if (I18n.isAvailableCoubicLocale(locale)) {
    setCoubicLocaleRequestHeader(locale);
  }
  return locale;
};

const setLocale = (ctx: GetServerSidePropsContext) => {
  const { hl } = nookies.get(ctx);
  const locale = (ctx.query.hl ||
    hl ||
    (ctx.req &&
      detectLocaleByAcceptLanguage(ctx.req.headers['accept-language'])) ||
    '') as string;
  if (I18n.isAvailableCoubicLocale(locale)) {
    setCoubicLocaleRequestHeader(locale);
  }
};

export const injectAppContextGetServerSideProps =
  <P,>(
    innerGetServerSideProps: (
      ctx: AppGetServerSidePropsContext,
    ) => Promise<GetServerSidePropsResult<P>>,
  ) =>
  async (
    ctx: GetServerSidePropsContext & { requestId?: string },
  ): Promise<GetServerSidePropsResult<P>> => {
    try {
      setUaType(ctx.req.headers['user-agent'] ?? '');
      setLocale(ctx);
      const { cb, token, uatype, user_agent } = nookies.get(ctx);
      const isCoubicApp = checkIsCoubicApp(uatype, user_agent);

      const result = await innerGetServerSideProps({
        ...ctx,
        // bffのリクエスト追跡してた名残 別でリファクタリングするため、無い場合は一旦空文字を入れておく
        requestId: ctx.requestId ?? '',
        session: cb ?? '',
        token: token ?? '',
        isCoubicApp,
      });
      return JSON.parse(JSON.stringify(result));
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response &&
        err.response.status === 404
      ) {
        return { notFound: true };
      }
      throw err;
    }
  };
export interface AppGetServerSidePropsContext
  extends GetServerSidePropsContext {
  session: string;
  token: string;
  requestId: string;
  isCoubicApp: boolean;
}

// ウォーターフォールを防ぐ
// https://swr.vercel.app/ja/docs/prefetching
preload(userMeSwrKey, useUserMeFetcher);

const CbcApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    // NProgress
    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', () => NProgress.done());
    router.events.on('routeChangeError', () => NProgress.done());

    // stripeの初期化
    const { publicRuntimeConfig } = getConfig();
    Stripe.setPublishableKey(publicRuntimeConfig.stripePublicKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locale = useLocale();

  return (
    <>
      <Script src="https://js.stripe.com/v2/" strategy="beforeInteractive" />
      <Script src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <Sentry.ErrorBoundary>
        <ViewDeviceContext.ViewDeviceContextProvider>
          <LocaleContext.LocaleContextProvider locale={locale}>
            <Provider store={store}>
              <SupervisorProxyHeader />
              <Component {...pageProps} />
            </Provider>
          </LocaleContext.LocaleContextProvider>
        </ViewDeviceContext.ViewDeviceContextProvider>
        {/* FIXME: inner html => SVGR を使う方法に改善する */}
        <div
          dangerouslySetInnerHTML={{ __html: svgSprite }}
          style={{ display: 'none' }}
        />
      </Sentry.ErrorBoundary>
    </>
  );
};

export default CbcApp;
